<template>
  <div
    class="px-3 md:px-0 container max-w-8xl md:pt-10 mx-auto relative"
    v-if="experience"
  >
    <MobileHeader
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="block md:hidden"
    />
    <Heading
      :headingText="getHeaderText()"
      routeName="OpenDemosCatalog"
      class="hidden md:block"
    />
    <div v-if="experience" class="mt-20 md:mt-5 bg-white rounded-xl">
      <div
        class="question-card mt-4 md:my-4 text-bgcolor bg-nColorFillGray border border-nColorStrokeGray rounded-xl m-4 md:m-0"
        v-for="question in questions"
        :key="question.question_id"
      >
        <div
          class="statistics flex flex-wrap"
          v-if="statistics[question.question_id] && question.has_played.status"
        >
          <div
            class="flex flex-col md:flex-row w-full bg-blue-900 text-white px-4 py-3 md:px-8 md:py-6 rounded-t-lg"
          >
            <div class="w-full">
              <h3 class="text-lg md:text-3xl font-bold mb-2">
                {{ question.title }}
              </h3>
              <p class="mb-2 text-sm md:text-xl">{{ question.description }}</p>
            </div>
            <div
              class="w-full grid place-content-start md:place-content-end text-sm md:text-xl md:mb-5 mt-2 md:mt-0"
            >
              <p>
                You answered
                <span class="font-semibold text-sm md:text-2xl"
                  >"{{
                    question.has_played.answer ||
                    answerLabels[question.question_id]
                  }}"</span
                >
              </p>
            </div>
          </div>
          <div class="w-full flex justify-center px-4 py-3 md:px-8 md:py-6">
            <TTALChart
              :chartData="chartFactory(statistics[question.question_id])"
            />
          </div>
        </div>

        <div class="form" v-else>
          <form
            action=""
            @submit.prevent="onSubmitAnswer(question.question_id)"
          >
            <div class="questions">
              <div
                class="question-header bg-blue-900 text-white px-4 py-3 md:px-8 md:py-6 rounded-t-lg"
              >
                <h3 class="text-base md:text-3xl font-bold mb-2">
                  {{ question.title }}
                </h3>
                <p class="mb-2 font-medium text-sm md:text-xl">
                  {{ question.description }}
                </p>
              </div>
              <div
                class="questions-content flex flex-wrap p-4 md:p-8 md:pl-16 border-b"
              >
                <div
                  class="question w-full md:w-1/2 mb-2 md:mb-0 flex justify-start items-start"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[question.question_id] == 'question1',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[question.question_id] != 'question1',
                    }"
                    class="w-full text-sm md:text-base inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'question1' + question.question_id"
                    >{{ question.question1 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="question.question_id"
                    :id="'question1' + question.question_id"
                    value="question1"
                    v-model="answers[question.question_id]"
                  />
                </div>
                <div
                  class="question w-full md:w-1/2 mb-2 md:mb-5 flex justify-start items-center"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[question.question_id] == 'question2',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[question.question_id] != 'question2',
                    }"
                    class="w-full text-sm md:text-base inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'question2' + question.question_id"
                    >{{ question.question2 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="question.question_id"
                    :id="'question2' + question.question_id"
                    value="question2"
                    v-model="answers[question.question_id]"
                  />
                </div>
                <div
                  class="question w-full md:w-1/2 mb-2 md:mb-0 flex justify-start items-center"
                >
                  <label
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[question.question_id] == 'question3',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white hover:font-bold':
                        answers[question.question_id] != 'question3',
                    }"
                    class="w-full text-sm md:text-base inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'question3' + question.question_id"
                    >{{ question.question3 }}</label
                  >
                  <input
                    required
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="question.question_id"
                    :id="'question3' + question.question_id"
                    value="question3"
                    v-model="answers[question.question_id]"
                  />
                </div>
                <div
                  class="question w-full md:w-1/2 mb-2 md:mb-0 flex justify-start items-center"
                >
                  <label
                    required
                    :class="{
                      'bg-nColorBlue text-white font-bold pl-6':
                        answers[question.question_id] == 'question4',
                      'bg-white text-black font-medium pl-6 hover:bg-nColorBlue hover:text-white':
                        answers[question.question_id] != 'question4',
                    }"
                    class="w-full text-sm md:text-base inline-block p-3 border border-nColorBlue rounded-3xl transition-all duration-300 ease-in-out cursor-pointer"
                    :for="'question4' + question.question_id"
                    >{{ question.question4 }}</label
                  >
                  <input
                    class="w-1/12 h-full shadow opacity-0 pointer-events-none"
                    type="radio"
                    :name="question.question_id"
                    :id="'question4' + question.question_id"
                    value="question4"
                    v-model="answers[question.question_id]"
                  />
                </div>
              </div>
            </div>

            <div class="w-11/12 text-right mx-6 py-4">
              <button
                class="bg-nButtonGreen text-white py-2 md:py-3 rounded-lg text-sm md:text-lg font-semibold px-6 md:px-12 shadow mr-4 md:mr-0"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="my-5">
      <ExpFeedback :exp="obkSlugs.ttal" />
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import ExpFeedback from "@/components/Demo/ExpFeedback.vue";
import MobileHeader from "@/components/Demo/MobileHeader.vue";
import Heading from "@/components/Demo/Heading.vue";
import obkSlugs from "@/common/slugs";
import TTALChart from "@/components/Demo/TTALChart.vue";
import Footer from "@/components/Demo/CatalogElements/DesktopFooter.vue";

export default {
  components: {
    TTALChart,
    ExpFeedback,
    MobileHeader,
    Heading,
    Footer,
  },
  created() {
    ApiService.get(apiResource.demo.getDetails, {
      slug: obkSlugs.ttal,
    })
      .then(({ data }) => {
        this.experience = data.data;
        this.questions = data.data.exp_details.questions;
      })
      .catch(() => {});
  },
  data() {
    return {
      experience: null,
      answers: {},
      answerLabels: {},
      statistics: {},
      questions: [],
      obkSlugs,
    };
  },
  methods: {
    getHeaderText() {
      return this.experience.name;
    },
    chartFactory(stats) {
      return {
        labels: stats.map((option) => option.label),
        data: stats.map((option) => option.percentage),
      };
    },
    onSubmitAnswer(id) {
      let percentages = this.randomPercentages();
      let questionDetails =
        this.questions[
          this.questions.findIndex((question) => question.question_id == id)
        ];
      this.answerLabels[id] = questionDetails[this.answers[id]];
      this.statistics[id] = [
        {
          label: questionDetails["question1"],
          percentage: percentages[0],
        },
        {
          label: questionDetails["question2"],
          percentage: percentages[1],
        },
        {
          label: questionDetails["question3"],
          percentage: percentages[2],
        },
        {
          label: questionDetails["question4"],
          percentage: percentages[3],
        },
      ];
      questionDetails.has_played.status = true;
    },
    randomPercentages() {
      var max = 100,
        segmentMax = 60,
        tempResults = [],
        remaining = max,
        segments = 4,
        finalResults = [];

      //create a series of random numbers and push them into an array
      for (var i = 1; i <= segments; i++) {
        var r = Math.random() * segmentMax;
        if (i === segments) {
          // the final segment is just what's left after the other randoms are added up
          r = remaining;
        }
        tempResults.push(r);
        // subtract them from the total
        remaining -= r;
        // no segment can be larger than what's remaining
        segmentMax = remaining;
      }

      //randomly shuffle the array into a new array
      while (tempResults.length > 0) {
        var index = Math.floor(Math.random() * tempResults.length);
        finalResults = finalResults.concat(
          Math.round(tempResults.splice(index, 1))
        );
      }
      return finalResults;
    },
  },
};
</script>
<style scoped></style>
